@import "src/styles/variables";

.flex {
  display: flex;
  gap: 1rem;
}

.section-title {
  font-weight: 700;
  font-size: 16px;
  color: $main-black;
  margin-bottom: 16px;
}

.informationContainer {
  padding: 5px;
  width: 100%;
  border-radius: $border-radius;
  background-color: #EFEFEF;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 16px;

  .informationsInput {
    width: 20%;
  }
}

.button {
  background: none;
  border: none;
}

.deleteButton {
  color: red;
}

.error {
  border: $border-red;
}