.container{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.219);
    height: 100vh;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}