@import "src/styles/variables";

.header {
  display: flex;
  border-bottom: $main-border;
  justify-content: space-between;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
}

.list {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}
