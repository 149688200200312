@import "src/styles/variables";

.dialog {
  max-width: 368px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.row {
  display: flex;
  gap: 1.5rem;
  margin-top: .75rem;
  align-items: flex-start;
}

.resetText {
  font-family: 'Lato', sans-serif;
  font-size: 0.875rem;
  line-height: 150%;
  color: $main-gray;
}

.bottomLabel {
  color: $main-gray;
  font-size: .75rem;
  margin-top: .5rem;
}

.link {
  color: $main-accent;
  text-decoration: underline;
  cursor: pointer;
}
