@import "src/styles/variables";

.container {
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.label {
  font-size: 0.875rem;
  font-weight: 400;
  color: $main-gray;
  margin-left: 0.5rem;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}

.toggle {
  border: none;
  background: none;
  transition: .15s linear;
}


.toggleOpen {
  transform: rotate(90deg);
}

.currentItem {
  font-weight: bold;
  color: rgb(0, 116, 0);
}