@import "src/styles/variables";

.container {
  padding: 2px 8px;
  border-radius: $border-radius;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  width: fit-content;

  &.success {
    background-color: $secondary-accent;
    color: $main-accent;
    margin: auto;
  }

  &.pending {
    background-color: $secondary-yellow;
    color: $main-yellow;
  }

  &.failed {
    background-color: $secondary-red;
    color: $main-red;
  }
}
