@import "src/styles/variables";

.container {
  padding: 1rem 1.25rem;
  border-radius: $border-radius;
  border: $card-border;
  background: $main-background;
  cursor: pointer;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.name {
  font-size: 0.875rem;
  color: $main-black;
}

.date {
  font-size: 0.875rem;
  color: $main-gray;
}

.vertical {
  height: 1rem;
  border-right: $header-border;
}

.button {
  border: none;
  background: none;
  transition: all 0.5s;
}

.toggleOpen {
  transform: rotate(90deg);
}

.red {
  color: $main-red;
}

.list {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-left: 5%;
}