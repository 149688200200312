@import "src/styles/variables";

.header {
  display: flex;
  border-bottom: $main-border;
  justify-content: space-between;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.list {
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
}

.buttons {
  display: flex;
  button {
    margin: 0 8px;
  }
}
.error {
  border-bottom: $border-red !important;
}
