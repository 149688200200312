@import "src/styles/variables";

.grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: calc(100vw - 26.5rem);
}

.column {
  display: flex;
  flex-direction: column;
  max-width: calc(100% / 3);
  gap: 0.75rem;
}

.sectionTitle {
  font-size: 1rem;
  color: $main-black;
  font-weight: 700;
}

.simulationButton {
  max-width: 11rem;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: baseline;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.renderContainer {
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.button {
  background: none;
  border: none;
}

.deleteColumn {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.headerText {
  padding-right: 2rem;
}

.deleteButton {
  color: $main-red;
}

.twoInput {
  width: 4rem;
}

.tableContainer {
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: 1rem;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.colorTd {
  background-color: $main-stroke;

  & input {
    background: transparent;
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;

  td,
  th {
    border-left: $header-border;
    border-top: $header-border;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;
    min-width: 11rem;
  }

  th {
    border-top: none;
    font-weight: 700;
    white-space: nowrap;
    position: relative;
    height: 5rem;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }
}

.order-selector {
  margin-top: 5rem;

  .item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    height: calc(3.7rem + 2px);
  }
}
