@import "src/styles/variables";

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.panelHeightContainer {
  display: flex;
  gap: 0.5rem;
}

.flex {
  display: flex;
  gap: 0.5rem;
}

.tooltipImage {
  width: 250px;
  aspect-ratio: 1/1;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.columnRender {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: #ddd;
  border-radius: $border-radius;
  padding: 1rem;
  height: fit-content;
}

.sectionTitle {
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  color: $main-black;
}

.mt {
  margin-top: 1.5rem;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  & input {
    padding-right: 0.5rem;
  }

  // short way to decrease padding for input icon
  & > div > div > div {
    padding-left: 0.5rem;
  }
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.renderContainer {
  position: relative;
  height: 300px;
  border-radius: $border-radius;
  border: 1px #ccc solid;
}

.drawButton {
  color: $main-accent;
  padding: 0.25rem;

  &:hover {
    background-color: transparent;
  }
}

.button {
  background: none;
  border: none;
}

.deleteButton {
  color: red;
}

.addPoint {
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.5rem;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: $main-border;
  border-radius: $border-radius;

  td,
  th {
    border-left: $main-border;
    border-top: $main-border;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;
  }

  th {
    border-top: none;
    font-weight: 700;
  }

  td:first-child,
  th:first-child {
    border-left: none;

    width: 70%;
  }

  td:last-child,
  th:last-child {
    width: 10%;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
}

.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}

.datepicker {
  width: 100%;

  & > div {
    width: 100%;

    & > div > div {
      display: flex;
      border-radius: calc($border-radius / 2);
      border: $input-border;

      & > div {
        flex: 1;
      }
    }
  }

  & input {
    font-size: 0.875rem;
    font-family: "Lato", sans-serif;
    color: $main-black;
    padding: 0.75rem 1rem;
  }
}
