@import "src/styles/variables";

.container {
  width: 100%;
}

.heading {
  position: relative;
  padding: 0.5rem 0;

  &.pointer {
    cursor: pointer;
  }
}

.toggle {
  border: none;
  background: none;
  transition: 0.15s linear;
}

.toggleOpen {
  transform: rotate(90deg);
}

.content {
  max-height: 0;
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  transform-origin: top;
  transform: scaleY(0);
  margin-left: 1.5rem;
}

.contentShow {
  max-height: 9999px;
  transform: scaleY(1);
}

.title {
  font-weight: 600;
  font-size: 0.875rem;
  color: $main-black;
  display: inline-block;
  text-decoration: none;
  user-select: none;
}

.counter {
  color: $main-gray;
  font-size: 0.75rem;
  margin-left: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
