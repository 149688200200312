@import "src/styles/variables";

.header {
  display: flex;
  border-bottom: $main-border;
  justify-content: space-between;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
}

.list {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.container {
  padding: 24px;
  position: relative;
}
.close {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  border: none;
  background: transparent;
}
.modal_title {
  margin: 24px 0 16px;
  font-size: 24px;
  font-weight: 700;
  color: $main-black;
}

.row {
  margin-left: auto;
  width: fit-content;
  display: flex;
  gap: 1.5rem;
  margin-top: 35px;
}
.button-delete {
  color: red;
}
.ml-10px{
  margin-left: 10px;
}

.flex {
  display: flex;
  gap: .5rem;
}