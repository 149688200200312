@import "src/styles/variables";

.container {
  padding: 1rem 1.25rem;
  border-radius: $border-radius;
  border: $card-border;
  background: $main-background;
  cursor: pointer;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.name {
  font-size: .875rem;
  color: $main-black;
}

.date {
  font-size: .875rem;
  color: $main-gray;
}

.vertical {
  height: 1rem;
  border-right: $header-border;
}

.button {
  border: none;
  background: none;
}

.red {
  color: $main-red;
}
