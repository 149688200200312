@import "src/styles/variables";

.headerTitle {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: $main-black;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
