@import "src/styles/variables";

.title {
  text-transform: uppercase;
  display: inline;
}

.logo {
  height: 20px;
  width: 112px;
  margin-right: 8px;
}

.header {
  padding: .5rem 2.5rem;
  display: flex;
  align-items: center;
  border-bottom: $header-border;
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.vertical {
  width: 1px;
  height: 12px;
  background-color: $main-stroke;
}
