@import "src/styles/variables";

.container {
  padding: 1rem 1.25rem;
  border-radius: $border-radius;
  border: $card-border;
  background: $main-background;
  cursor: pointer;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.name {
  font-size: 0.875rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 150%;
}

.option {
  font-size: 0.875rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 150%;
  color: $main-gray;
  display: flex;
  align-items: flex-end;
  max-width: 325px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
}

.vertical {
  height: 1rem;
  border-right: $header-border;
}

.button {
  border: none;
  background: none;
  &.copy_to_other_project {
    width: 30px;
    aspect-ratio: 1/1;
    svg {
      width: 100%;
      height: 100%;
    }
    
  }
}

.red {
  color: red;
}

.file {
  margin-right: 10px;
  svg {
    margin-bottom: 3px;
  }
}

.icon {
  transform: translateY(-2px);
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  width: 0.875rem;
  height: 0.875rem;
  border-width: 0.125rem;
}
