//// Lato 
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato/hinted-Lato-Regular.eot');
    src: local('Lato Regular'), local('Lato-Regular'),
        url('../../assets/fonts/Lato/hinted-Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Lato/hinted-Lato-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Lato/hinted-Lato-Regular.woff') format('woff'),
        url('../../assets/fonts/Lato/hinted-Lato-Regular.ttf') format('truetype'),
        url('../../assets/fonts/Lato/hinted-Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato/Lato-Bold.eot');
    src: local('Lato Bold'), local('Lato-Bold'),
        url('../../assets/fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Lato/Lato-Bold.woff2') format('woff2'),
        url('../../assets/fonts/Lato/Lato-Bold.woff') format('woff'),
        url('../../assets/fonts/Lato/Lato-Bold.ttf') format('truetype'),
        url('../../assets/fonts/Lato/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

//// Poppins

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/hinted-Poppins-Medium.eot');
    src: local('Lato Medium'), local('Lato-Medium'),
        url('../../assets/fonts/Poppins/hinted-Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/hinted-Poppins-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/hinted-Poppins-Medium.woff') format('woff'),
        url('../../assets/fonts/Poppins/hinted-Poppins-Medium.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/hinted-Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


/// Inter

@font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/hinted-Inter-Regular.eot');
    src: local('Lato Regular'), local('Lato-Regular'),
        url('../../assets/fonts/Inter/hinted-Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Inter/hinted-Inter-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Inter/hinted-Inter-Regular.woff') format('woff'),
        url('../../assets/fonts/Inter/hinted-Inter-Regular.ttf') format('truetype'),
        url('../../assets/fonts/Inter/hinted-Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/hinted-Inter-SemiBold.eot');
    src: local('Lato SemiBold'), local('Lato-SemiBold'),
        url('../../assets/fonts/Inter/hinted-Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Inter/hinted-Inter-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/Inter/hinted-Inter-SemiBold.woff') format('woff'),
        url('../../assets/fonts/Inter/hinted-Inter-SemiBold.ttf') format('truetype'),
        url('../../assets/fonts/Inter/hinted-Inter-SemiBold.svg#Inter-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}