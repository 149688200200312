@import "src/styles/variables";

.absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.modalBackground {
  position: fixed;
  top: 0;
  background-color: #00000044;
  width: 100vw;
  height: 100vh;

  .modalContainer {
    position: relative;
    width: max-content;
    min-height: 50%;
    max-height: 80%;
    height: max-content;
    top: 10%;
    margin: auto;
    padding: 0.5rem 1rem;
    background-color: #f9f9f9;
    border: $header-border;
    border-radius: 10px;
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;

    .leftSide {
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      .templateSelectorLabel {
        font-family: "Lato", sans-serif;
        font-size: x-large;
        padding: 0.5rem 1rem;
      }
      .templateSelector {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 0.5rem 1rem;

        .templateSelectorItem {
          display: flex;
          gap: 0.25rem;
          align-items: center;
          padding: 0.15rem 0.2rem;
          border-radius: 10px;
          border: 1px white solid;

          cursor: pointer;

          transition: background-color 0.25s, color 0.25s;

          &:hover {
            background-color: #777;
            color: white;
          }

          &.selected {
            border-color: black;
          }
        }
      }

      .templateTypeSelector {
        display: flex;
        justify-content: space-evenly;
        // flex-direction: column;
        // gap: 0.25rem;
        padding: 0.5rem 1rem;

        .templateTypeSelectorItem {
          display: flex;
          gap: 0.25rem;
          align-items: center;
          padding: 0.15rem 0.2rem;
          border-radius: 10px;
          border: 1px white solid;

          cursor: pointer;

          transition: background-color 0.25s, color 0.25s;

          &:hover {
            background-color: #777;
            color: white;
          }

          &.selected {
            border-color: black;
          }
        }
      }
    }
    .verticalLimiter {
      height: 100%;
      border: 3px #e0e0e0 solid;
    }
    .rightSide {
      width: fit-content;
      background-color: #fff;
      border-radius: 10px;
      overflow-y: scroll;
      padding-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      padding-top: 0.5rem;
      gap: 0.5rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .templateLabel {
        font-family: "Lato", sans-serif;
        font-size: x-large;
        padding: 0.5rem 1rem;
        background-color: white;
        border: 0;
      }

      .selectButton {
        padding: 0.5rem 1rem;
        height: max-content;
        width: max-content;
        margin-block: auto;
        margin-right: 0.5rem;
        background-color: $main-accent;
        border-radius: 10px;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        color: white;
        border: 2px $main-accent solid;
        display: flex;
        gap: 0.2rem;

        transition: all 0.1s;

        &.edit {
          width: 150px;
          &:focus {
            outline: none;
          }
          border-color: #d3ac38;
          background-color: #d3ac38;
        }
        &.disabled {
          cursor: not-allowed;
          filter: blur(2px);
        }

        &:hover:not(.disabled) {
          background-color: white;
          color: black;
        }
      }
      .cancelButton {
        padding: 0.5rem 1rem;
        height: max-content;
        margin-block: auto;
        // margin-right: 0.5rem;
        border-radius: 10px;
        // right: 1rem;
        top: 1rem;
        cursor: pointer;
        color: black;
        display: flex;
        gap: 0.2rem;

        transition: all 0.1s;

        &.edit {
          &:focus {
            outline: none;
          }
          border-color: #d3ac38;
          background-color: #d3ac38;
        }
        &.disabled {
          cursor: not-allowed;
          filter: blur(2px);
        }

        &:hover {
          background-color: #bbb;
          color: black;
        }
      }
    }

    .littleLimiter {
      width: 100%;
      border: 0.5px $main-stroke solid;
    }
  }
}

.panelHeightContainer {
  display: flex;
  gap: 0.5rem;
}

.flex {
  display: flex;
  gap: 0.5rem;
}

.tooltipImage {
  width: 250px;
  aspect-ratio: 1/1;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.columnRender {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: #ddd;
  border-radius: $border-radius;
  padding: 1rem;
}

.sectionTitle {
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  color: $main-black;
}

.mt {
  margin-top: 1.5rem;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  & input {
    padding-right: 0.5rem;
  }

  // short way to decrease padding for input icon
  & > div > div > div {
    padding-left: 0.5rem;
  }
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.renderContainer {
  position: relative;
  height: 300px;
  border-radius: $border-radius;
  border: 1px #ccc solid;
}

.drawButton {
  color: $main-accent;
  padding: 0.25rem;

  &:hover {
    background-color: transparent;
  }
}

.button {
  background: none;
  border: none;
}

.deleteButton {
  color: red;
}

.addPoint {
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.5rem;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: $main-border;
  border-radius: $border-radius;

  td,
  th {
    border-left: $main-border;
    border-top: $main-border;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;
  }

  th {
    border-top: none;
    font-weight: 700;
  }

  td:first-child,
  th:first-child {
    border-left: none;

    width: 70%;
  }

  td:last-child,
  th:last-child {
    width: 10%;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
}

.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}

.datepicker {
  width: 100%;

  & > div {
    width: 100%;

    & > div > div {
      display: flex;
      border-radius: calc($border-radius / 2);
      border: $input-border;

      & > div {
        flex: 1;
      }
    }
  }

  & input {
    font-size: 0.875rem;
    font-family: "Lato", sans-serif;
    color: $main-black;
    padding: 0.75rem 1rem;
  }
}
