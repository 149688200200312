@import "src/styles/variables";

.item {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
}

.delete {
  color: $main-red;

  &:hover {
    color: $main-red;
  }
}

.button {
  display: flex;
  align-items: center;
  border-radius: 25%;

  &:hover {
    background-color: #00000011;
  }
}
