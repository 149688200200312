@import "src/styles/variables";

.container {
  width: 100%;
  position: relative;
}

.input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.625rem 0.5rem;
  outline: none;
  border: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
  color: $main-black;
  border: $input-border;
  border-radius: calc($border-radius / 2);
  cursor: pointer;
  position: relative;
  user-select: none;

  & > span {
    flex: 1;
  }

  &.errorBorder {
    border: $border-red;
  }
}

.menu {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  max-height: 400px;
  position: absolute;
  border-radius: calc($border-radius / 2);
  padding: 0.75rem 1rem;
  background-color: $white-color;
  overflow-y: auto;
  z-index: 1;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);

  &[data-popper-placement='top'] {
    bottom: calc(100% - 1.5rem);
  }

  &[data-popper-placement='bottom'] {
    top: calc(100% + 0.5rem);
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.search {
  margin-bottom: 1rem;
}
