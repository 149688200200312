@import "src/styles/variables";

.title {
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-invalid{
  position: relative;
  bottom: -5px;
}

.subtitle {
  color: $main-gray;
  margin: 7px 0 24px ;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.logo {
  display: flex;
  margin: 10px auto 34px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.button-text{
  width: 100%;
  text-align: center;
}
.eyes-wrapper{
  background: transparent;
  border: none;
}
.eyes{
  color: $main-black;
}
