@import "src/styles/variables";

.container {
  border: $card-border;
  border-radius: $border-radius;
  padding: 1.25rem;
  background-color: #0000bb22;
  display: flex;
  flex-direction: column;
  height: fit-content;
  cursor: pointer;
}

.infoContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.projectList {
  border-radius: $border-radius;
  background-color: #0000bb11;
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;

  &.open {
    max-height: 300px;
    overflow-y: scroll;

    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}

.name {
  font-size: 0.875rem;
  color: $main-black;
  font-weight: 600;
  margin-bottom: 0.25rem;
  text-decoration: none;
  display: block;
  display: flex;
  gap: 0.5rem;
}

.date {
  font-size: 0.75rem;
  color: $main-gray;
}

.icon {
  svg {
    width: 20px;
    height: 20px;
    color: $main-gray;
  }
}

.spaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.plusIcon {
  position: absolute;

  width: 20px;
  height: 20px;
  background-color: red;

  right: 1rem;
  bottom: 1rem;
}

.expandButton {
  all: unset;
  cursor: pointer;
}

.emptyZone {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  .dragZone {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    border: black dashed 2px;
  }
}

.dropHighlight {
  border: 1px dashed #4caf50;
  background-color: rgba(76, 175, 80, 0.1);
}

.list {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
}
