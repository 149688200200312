@import "src/styles/variables";

.container{
    padding: 0px 10px 10px 10px;
}
.title {
  margin-top: 25px;
  margin-bottom: 16px;
  font-size: 24px;
  color: $main-black;
  font-weight: 700;
  padding: 0;
}
.text {
  color: $main-gray;
  font-size: 14px;
  font-weight: 400;
}
.successIcon {
  svg {
    color: $main-accent;
    width: 50px;
    height: 50px;
  }
}
