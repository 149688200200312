@import "src/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  flex: 1;
}

.input {
  width: 100%;
  padding: 0.75rem 1rem;
  outline: none;
  border: none;
  border-radius: calc($border-radius / 2);
  box-sizing: border-box;
  font-size: 1.125rem;
  font-family: "Lato", sans-serif;
  color: $main-black;
  font-weight: 700;

  &:focus {
    background: $main-stroke;
  }

  &.errorBorder {
    border: $border-red;
  }
}
