@import "src/styles/variables";

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0;
  z-index: 0;
  &__background {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100vh;

    object-fit: cover;
    top: 0;
    left: 0;
  }
  &::after {
    position: absolute;
    z-index: -1;
    content: "";
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
  }
}

.window {
  transform: translateY(50%);
  z-index: 1;
  max-width: 477px;
  margin: 0 auto;
  border-radius: $border-radius;
  background-color: $white-color;
  padding: 2rem;
}
