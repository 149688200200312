@import "src/styles/variables";

.header {
  padding: 2rem 2.5rem;
}

.content {
  padding: 0 2.5rem 3rem;
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 17.5rem 1fr;
}

.row {
  display: flex;
  justify-content: space-between;
}

.aside {
  border-radius: $border-radius;
  background-color: $white-color;
  height: fit-content;
}

.projectTitle {
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  color: $main-black;
}

.asideTitle {
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  color: $main-black;
  padding: 1.75rem 1rem 0.875rem;
}

.link {
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  color: $main-accent;
  text-decoration: none;
  padding: 0.75rem 1rem;
  display: block;
  cursor: pointer;
}

.active {
  background: #F8FFFB;
}
