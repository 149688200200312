@import "src/styles/variables";

.form {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.row {
  display: flex;
  gap: 1.5rem;
  margin-top: .75rem;
}

.inputRow {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
