@import "src/styles/variables";

.container {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.label {
  font-size: 0.875rem;
  font-weight: 400;
  color: $main-gray;
  margin-left: 0.5rem;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}

.currentItem {
  font-weight: bold;
  color: rgb(0, 116, 0);
}