@import "src/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wrapper {
  margin: 2.5rem auto 0;
  max-width: 1240px;
  padding: 0 1.25rem;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.control {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.title {
  font-size: 2rem;
  color: $main-black;
}

.projectsSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1.25rem;
  background-color: $white-color;
  border-radius: $border-radius;
}

