@import "src/styles/variables";

.flex {
  display: flex;
  gap: 1rem;
}

.deleteButton {
  color: $main-red;
}
