@import "src/styles/variables";

.container {
  width: 100%;
}

.label {
  color: $main-black;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  font-family: "Lato", sans-serif;
  white-space: nowrap;
  height: min-content;
  margin-block: auto;
}
