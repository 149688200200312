@import "src/styles/variables";

.form {
  label {
    margin-top: 12px;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.row {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.75rem;
}
.input-label {
  width: max-content;
  color: $main-black;
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  font-family: "Lato", sans-serif;
}
.dates {
  display: flex;
  margin: 0 -8px 24px;
  position: relative;
}
.dates > div {
  margin: 0 8px;
}
.date-error {
  position: absolute;
  bottom: -30px;
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  &--left {
    left: 16px;
  }
  &--right {
    right: 16px;
  }
}
.input-invalid {
  input {
    border: 1px solid red;
  }
}

.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}
