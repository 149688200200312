@import "src/styles/variables";

.container {
  border-radius: $border-radius;
  display: flex;
}

.button {
  border: 1px solid $main-accent;
  background-color: $white-color;
  color: $main-accent;
  padding: 0.375rem 0.675rem;
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;

  &:nth-child(1) {
    border-radius: $border-radius 0 0 $border-radius;
  }
  &:nth-child(2) {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.checked {
  background-color: $main-accent;
  color: $white-color;
}

button:disabled {
  cursor: not-allowed;
}