@import "src/styles/variables";

.container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: .875rem;
  user-select: none;
  padding: 0.5rem 0 0.5rem 1.75rem;
}

.container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: $white-color;
  border-radius: calc($border-radius / 2);
  border: $input-border;
}

.container:hover input ~ .checkmark {
  background-color: $main-background;
}

.container input:checked ~ .checkmark {
  background-color: $main-accent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.disabled {
  cursor: not-allowed;
}