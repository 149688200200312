@import "src/styles/variables";

.container {
  padding: 24px;
  position: relative;
}
.close {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  border: none;
  background: transparent;
}
.title {
  margin: 24px 0 16px;
  font-size: 24px;
  font-weight: 700;
  color: $main-black;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  color: $main-gray;
}
.row {
  display: flex;
  gap: 1.5rem;
  margin-top: 35px;
}
.button-delete {
  color: red;
}
.ml-10px{
  margin-left: 10px;
}
