@import "src/styles/variables";

.container {
  width: 100%;
}

.label {
  width: 100%;
  color: $main-black;
  display: block;
  font-size: .75rem;
  margin-bottom: .5rem;
}

.input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: $input-border;
  border-radius: calc($border-radius / 2);
  box-sizing: border-box;
  font-size: .875rem;
  resize: none;

  &.error {
    border-color: $main-red;
  }
}
