@import "src/styles/variables";

.header {
  padding: 2rem 2.5rem;
}

.content {
  padding: 0 2.5rem 3rem;
  display: grid;
  gap: 1.25rem;
}

.row {
  display: flex;
  justify-content: space-between;
}

.stickyContainer {
  position: sticky;
  top: 5vh;
  height: fit-content;
  
}

.aside {
  padding: 1rem 1.5rem;
  border-radius: $border-radius;
  background-color: $white-color;
  
  height: 90vh;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.projectTitle {
  font-size: 1.5rem;
  color: $main-black;
}

.resultsContainer {
  margin-left: 1.5rem;
}

.dragBorderContainer {
  // min-height: 100vh;
  transform: translateY(-1rem);
  top: 0;
  position: sticky;
  background-color: red;
}


.dragBorder {
  transform: translateX(1.5rem);
  height: 90vh;
  width: 10px;
  position: absolute;
  right: 0;
  cursor: ew-resize;
  user-select: none;

  &:hover, &:active {
    background-color: #00000011;
  }

}
