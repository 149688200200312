@import "src/styles/variables";

.absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.modalBackground {
  position: fixed;
  top: 0;
  background-color: #00000044;
  width: 100vw;
  height: 100vh;

  .modalContainer {
    position: relative;
    width: max-content;
    min-height: 50%;
    max-height: 80%;
    max-width: 100vw;
    height: max-content;
    top: 20%;
    margin: auto;
    padding: 0.5rem 1rem;
    background-color: #f9f9f9;
    border: $header-border;
    border-radius: 10px;
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;

    .leftSide {
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      overflow-y: scroll;
      .templateSelectorLabel {
        font-family: "Lato", sans-serif;
        font-size: x-large;
        padding: 0.5rem 1rem;
      }
      .templateSelector {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 0.5rem 1rem;

        .templateSelectorItem {
          display: flex;
          gap: 0.25rem;
          align-items: center;
          padding: 0.15rem 0.2rem;
          border-radius: 10px;
          border: 1px white solid;

          cursor: pointer;

          transition: background-color 0.25s, color 0.25s;

          &:hover {
            background-color: #777;
            color: white;
          }

          &.selected {
            border-color: black;
          }
        }
      }

      .templateTypeSelector {
        display: flex;
        justify-content: space-evenly;
        // flex-direction: column;
        // gap: 0.25rem;
        padding: 0.5rem 1rem;

        .templateTypeSelectorItem {
          display: flex;
          gap: 0.25rem;
          align-items: center;
          padding: 0.15rem 0.2rem;
          border-radius: 10px;
          border: 1px white solid;

          cursor: pointer;

          transition: background-color 0.25s, color 0.25s;

          &:hover {
            background-color: #777;
            color: white;
          }

          &.selected {
            border-color: black;
          }
        }
      }
    }
    .verticalLimiter {
      height: 100%;
      border: 3px #e0e0e0 solid;
    }
    .rightSide {
      overflow: scroll;

      width: fit-content;
      background-color: #fff;
      border-radius: 10px;
      overflow-y: scroll;
      padding-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      padding-top: 0.5rem;
      gap: 0.5rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .templateLabel {
        font-family: "Lato", sans-serif;
        font-size: x-large;
        padding: 0.5rem 1rem;
        background-color: white;
        border: 0;
      }

      .selectButton {
        padding: 0.5rem 1rem;
        height: max-content;
        width: max-content;
        margin-block: auto;
        margin-right: 0.5rem;
        background-color: $main-accent;
        border-radius: 10px;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        color: white;
        border: 2px $main-accent solid;
        display: flex;
        gap: 0.2rem;

        transition: all 0.1s;

        &.edit {
          width: 150px;
        &:focus {
            outline: none;
          }
          border-color: #d3ac38;
          background-color: #d3ac38;
        }
        &.disabled {
          cursor: not-allowed;
          filter: blur(2px);
        }

        &:hover:not(.disabled) {
          background-color: white;
          color: black;
        }
      }
      .cancelButton {
        padding: 0.5rem 1rem;
        height: max-content;
        margin-block: auto;
        // margin-right: 0.5rem;
        border-radius: 10px;
        // right: 1rem;
        top: 1rem;
        cursor: pointer;
        color: black;
        display: flex;
        gap: 0.2rem;

        transition: all 0.1s;

        &.edit {
          &:focus {
            outline: none;
          }
          border-color: #d3ac38;
          background-color: #d3ac38;
        }
        &.disabled {
          cursor: not-allowed;
          filter: blur(2px);
        }

        &:hover {
          background-color: #bbb;
          color: black;
        }
      }

      .tableContainer {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;

        .table {
          border-collapse: separate;
          border-spacing: 0;
          border: $header-border;
          border-radius: $border-radius;
          width: 95%;

          text-align: center;

          th {
            border-left: $header-border;
            border-top: $header-border;
            padding: 0.2rem 0.5rem;
            font-family: "Lato", sans-serif;
            font-size: 1rem;
            color: $main-black;
            // width: 15%;
            border-top: none;
            font-weight: 700;
            width: max-content;
            // white-space: nowrap;
          }

          td {
            border-left: $header-border;
            border-top: $header-border;
            padding: 0.15rem 2rem;
            white-space: nowrap;
            font-size: 0.85rem;
          }

          td:first-child,
          th:first-child {
            white-space: normal;
            border-left: none;
          }
        }
      }
    }

    .littleLimiter {
      width: 100%;
      border: 0.5px $main-stroke solid;
    }
  }
}
