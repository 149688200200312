@import "src/styles/variables";

.container {
  border: $card-border;
  border-radius: $border-radius;
  padding: 1.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $secondary-background;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.initials {
  border: $header-border;
  border-radius: $border-radius;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $main-black;
  font-size: 0.75rem;
  background-color: $white-color;
}

.name {
  font-size: 0.875rem;
  color: $main-black;
  font-weight: 600;
  margin-bottom: 0.25rem;
  text-decoration: none;
  display: block;
}

.projects {
  font-size: 0.75rem;
  color: $main-black;
  font-weight: 400;
}

.date {
  font-size: 0.75rem;
  color: $main-gray;
}
