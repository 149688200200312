@import "src/styles/variables";

.flex {
  display: flex;
}

button {
  height: max-content;
}

.tableContainer {
  // width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-start;

  table {
    // width: 100%;
    border-spacing: 0;
    // border-bottom: $header-border;
    // border: $header-border;
    border-radius: $border-radius;

    td,
    th {
      border-right: $header-border;
      border-top: $header-border;
      text-align: center;
      // background-color: $white-color;

      padding: 0.75rem 1rem;
      font-family: "Lato", sans-serif;
      font-size: 0.875rem;
      color: $main-black;
    }

    td:first-child,
    th:first-child {
      border-left: $header-border;
    }
    td {
      border-top: none;
      border-bottom: $header-border;
    }
    th {
      font-weight: 700;
      border-bottom: $header-border;

      &.no_border {
        border: none;
      }
    }

    th:first-child {
      border-top-left-radius: $border-radius;
    }

    th:last-child {
      border-top-right-radius: $border-radius;
    }

    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-family: "Lato", sans-serif;

  .delete {
    background-color: transparent;
    border: none;

    svg {
      transform: translateY(-3px);

      &:hover {
        color: red;
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.spinnerContainer {
  width: 100%;
  // height: 350px;
  // background-color: #00000022;
  display: flex;
  .spinner {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    align-items: center;
  }
}
