@import "src/styles/variables";

.container {
  width: 100%;

  & > div {
    width: 100%;

    & > div > div {
      display: flex;
      border-radius: calc($border-radius / 2);
      border: $input-border;

      & > div {
        flex: 1;
      }
    }
  }

  & input {
    font-size: 0.875rem;
    font-family: "Lato", sans-serif;
    color: $main-black;
    padding: 0.75rem 1rem;
  }
}

.label {
  width: 100%;
  color: $main-black;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  font-family: "Lato", sans-serif;
}
