@import "src/styles/variables";

.button {
  border-radius: $border-radius;
  border: $border-accent;
  background-color: $main-accent;
  color: $white-color;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
  }
}

.label {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;

  &.isLoading {
    opacity: 0;
  }
}

.labelContainer {
  position: relative;
}

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 0.875rem;
  height: 0.875rem;
  border-width: 0.125rem;
}

.fill {
  background-color: $main-accent;
  color: $white-color;

  &:hover:not([disabled]) {
    background-color: $white-color;
    color: $main-accent;
  }
}

.outlined {
  background-color: transparent;
  color: $main-black;
  border: $header-border;

  &:hover:not([disabled]) {
    background-color: $main-gray;
    color: $white-color;
  }
}

.text {
  color: $main-black;
  background: none;
  border: none;

  &:hover:not([disabled]) {
    background-color: $main-stroke;
  }
}

.fullwidth {
  width: 100%;
}

.iconBefore {
  margin-right: 0.75rem;
  display: inline-flex;
}
