@import "src/styles/variables";

.container {
  display: flex;
  align-items: center;
  padding: 0.125rem 0;
}

.doted {
  flex: 1;
  height: 0;
  margin: 0 1rem;
  border-top: 1px dashed $main-gray;
}

.item1 {
  max-width: 40%;
  display: flex;
  align-items: center;

  & > * {
    margin: 0;
  }

  @media (max-width: 1440px) {
    max-width: 35%;
  }
}

.item2 {
  width: 45%;

  @media (max-width: 1440px) {
    width: 55%;
  }
}
