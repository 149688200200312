@import "src/styles/variables";

.title {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: $main-black;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: calc(40% - 0.5rem);
}

.simuFlex {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.essenceContainer {
  padding: 1rem;
  background: $secondary-background;
  border-radius: $border-radius;
  border: $card-border;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.simuEssenceContainer {
  height: fit-content;
  margin-left: auto;
  padding: 1rem;
  background: $secondary-background;
  border-radius: $border-radius;
  border: $card-border;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.essenceContent {
  margin-top: 0;
  max-height: 0;
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  transform-origin: top;
  transform: scaleY(0);
}

.essenceContentShow {
  transition: max-height 0.15s ease-in-out;
  margin-top: 1rem;
  max-height: 9999px;
  transform: scaleY(1);
}

.essenceRow {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.essenceLabel {
  font-family: "Lato", sans-serif;
  color: $main-black;
  font-size: 0.875rem;
  align-items: center;
  display: flex;

  span {
    margin-left: 0.5rem;
  }
}

.essenceName {
  font-family: "Lato", sans-serif;
  color: $main-black;
  font-size: 0.875rem;
}

.statusTitle {
  font-family: "Lato", sans-serif;
  color: $main-black;
  font-weight: 700;
  font-size: 1rem;
}

.description {
  font-family: "Lato", sans-serif;
  color: $main-gray;
  font-size: 0.875rem;
  line-height: 1.5;
  word-break: break-all;
}

.waitContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: $white-color;
  border-radius: $border-radius;
  border: $card-border;
  width: 100%;
  margin-inline: auto;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.progressBarContainer {
  background-color: $white-color;
  border-radius: $border-radius;
  border: $card-border;
  display: flex;
  width: calc(100% - 0.5rem);
  padding-block: 2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  .waitItem {
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $main-accent;
  }
}

.spinner {
  --bs-spinner-height: 5rem;
  --bs-spinner-width: 5rem;
  --bs-spinner-border-width: 0.5rem;
  --bs-spinner-animation-speed: 1.5s;
}

.inProgress {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  color: $main-gray;
  margin-top: 1.5rem;
}

.cancelBtn {
  max-width: fit-content;
}

.expectedTime {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  color: $main-black;
  margin-top: 0.25rem;
}

.progressBar {
  width: 19.5rem;
  margin-top: 0.75rem;
  height: 0.5rem;
  border-radius: $border-radius;
  border: 0.5px solid $main-accent;

  & > div {
    background-color: $main-accent;
  }
}

.simulationResultInfoContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.onProgress {
    width: max-content;
    margin-inline: auto;
    // justify-content: center;
  }

  .simulationResultInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // width: 50%;
    // background-color: red;
  }
  .simulationResultImage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    &.onProgress {
      width: max-content;
    }
    // background-color: red;
  }
}

.progressBarLabel {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: $main-black;
  margin-top: 0.75rem;
}

.list {
  width: 100%;
  padding: 1rem 2rem 0;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;

  td,
  th {
    border-left: $header-border;
    border-top: $header-border;
    // background-color: $white-color;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;
  }

  th {
    border-top: none;
    font-weight: 700;
  }

  th:first-child {
    border-top-left-radius: $border-radius;
  }

  th:last-child {
    border-top-right-radius: $border-radius;
  }

  td:first-child {
    border-bottom-left-radius: $border-radius;
  }

  td:last-child {
    border-bottom-right-radius: $border-radius;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }
}

.limiter {
  width: 100%;
  border: $header-border;
  margin-block: 1rem;
}

.verticalLimiter {
  border: $header-border;
  margin-inline: 1rem;
}

.waterBalanceChartsContainer {
  margin-top: 1rem;
  background: $secondary-background;
  border-radius: $border-radius;
  border: $card-border;
  display: flex;
  width: calc(100vw - 300px - 4rem - 3px);
}

.chartContainer {
  width: calc(50% - 0.5rem);
}

.waterBalanceTable {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;

  th,
  td {
    border: 1px gray solid;
    padding: 0.2rem 0.5rem;
  }
  td {
    text-align: center;
  }
  th {
    background: #ddd;
  }
}

.labelsContainer {
  width: 95%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
}

.optionContainer {
  display: flex;
  justify-content: space-between;
}

.downloadContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.imgDownload  {

  margin-right: 30px;
  margin-top: 10px;

  svg {
    width: 30px;
    height: 30px;
  }
}

.uniteSelector {
  width: max-content;
}

.periodsChartsContainerLabel {
  font-family: "Lato", sans-serif;
  font-size: 2rem;
  color: $main-black;
  margin-inline: auto;
}

.chartTitle {
  width: max-content;
  display: flex;
  margin-inline: auto;
  text-decoration: underline;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  color: $main-black;
}

.chartToPngButton {
  padding: 2px;
  width: fit-content;
  height: fit-content;
  border-radius: 25%;
  background-color: transparent;
  color: black;
  cursor: pointer;
  transition: 10ms;

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 30px;
    height: 30px;
  }
}

.toggle {
  border: none;
  background: none;
  transition: 0.15s linear;
  margin-right: 0.25rem;
}

.toggleOpen {
  transform: rotate(90deg);
}

.chartTypeSelector {
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;

  .option {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    color: $main-black;
    white-space: pre;
    background-color: #00000044;
    align-self: center;
    border-radius: 10px;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    flex: 1;

    cursor: pointer;

    &:hover {
      padding: calc(0.75rem - 5px) calc(0.5rem - 5px);
      border-width: 5px;
      border-style: solid;
      border-color: #00000044;
    }
  }

  .active {
    padding: calc(0.75rem - 5px) calc(0.5rem - 5px);
    border-width: 5px;
    border-style: solid;
  }
}

.allPeriodsChartContainer {
  padding: 0.5rem;
  width: 100%;
  height: fit-content;
  background: $secondary-background;
  border-radius: $border-radius;
  border: $card-border;
}

.help-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}

.tableContainer {
  margin-top: 1rem;
  overflow-x: auto;
}

.tableContainerOverflow {
  overflow-x: auto;
  padding-bottom: 1rem;
  width: calc(100vw - 300px - 4rem - 3px);
}

.table {
  border: 1px solid #e9f2e9;
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;

  tbody,
  tr {
    border-radius: $border-radius;
  }

  td,
  th {
    border-left: $header-border;
    border-top: $header-border;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;

    & input {
      background: transparent;
    }
  }

  td:not(:last-child),
  th:not(:last-child) {
    min-width: 11rem;
  }

  th {
    border-top: none;
    font-weight: 700;
    white-space: nowrap;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

.dataTable {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;
  width: 100%;
  margin-top: 5px;

  tbody,
  tr {
    border-radius: $border-radius;
  }

  td,
  th {
    border-left: $header-border;
    border-top: $header-border;
    max-width: 11rem;
    text-align: center;

    // padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;

    & input {
      background: transparent;
    }
  }

  th {
    position: relative;
    width: 200px;
    min-width: 140px;
    border-top: none;
    font-weight: 700;
    // white-space: nowrap;

    .subTitle {
      font-size: 0.65rem;
    }
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

.flex {
  display: flex;
  gap: 0.5rem;
}

.justifyCenter {
  justify-content: center;
}

.customTooltip {
  background-color: $main-background;
  border-radius: $border-radius;
  border: $header-border;
  padding: 0.5rem;
  filter: opacity(0.8);
}

.icon {
  // width: 10px;
  // height: 10px;
  // // aspect-ratio: 1/1;

  svg {
    width: 25px;
    height: 25px;
  }
}

.dataset {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
