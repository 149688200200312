@import "src/styles/variables";

.dropdown {
  margin-left: auto;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;

  & > svg {
    margin-right: 0.75rem;
    width: 20px;
    height: 20px;
  }
}

.dropdownDivider {
  width: 90%;
  margin: 0.5rem auto;
}

.container {
  display: inline-flex;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.name {
  font-weight: 600;
  font-size: 0.75rem;
  color: $main-black;
}

.email {
  font-weight: 400;
  font-size: 0.75rem;
  color: $main-gray;
}

.initials {
  border: $header-border;
  background-color: $white-color;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.img {
  width: 2rem;
  height: 2rem;
  border: $main-border;
  border-radius: 50%;
  margin-left: 1rem;
}

.selector {
  display: flex;
  justify-content: space-between;
  padding: 1rem 5rem;

  .icon {
    cursor: pointer;

    padding: 0.5rem;
    border-radius: 10px;
    border: 3px transparent solid;
    &:hover {
      background-color: #00000033;
    }

    svg {
      width: 50px;
      height: 50px;
    }

    &.selected {
      border: 3px #00000033 solid;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
}

.bigIcon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 35px;
    height: 35px;
  }
}

.alertContainer {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  // width: 50%;
}

.preview {
  width: 100%;
  height: 200px;
  border-radius: $border-radius;
  border: $input-border;
  overflow: hidden;

  &.information {
    background-color: #33ff3355;
  }
  &.warning {
    background-color: #ff993355;
  }
  &.deploy {
    background-color: #6666ff55;
  }
  // padding: 1rem;

  .titleContainer {
    padding: 0 0.5rem;
    display: flex;
    gap: 1rem;
    width: 100%;
    height: 25%;
    background-color: #00000011;
    align-items: center;
    font-family: "Lato", sans-serif;
    border-bottom: $input-border;
  }
}

.text {
  padding: 0 0.5rem;
  width: 100%;
  height: 100%;

  overflow: scroll;

  textarea {
    all: unset;
    width: 100%;
    height: 150px;
  }
}

.modalUserAlert {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  &.show {
    display: block;
  }

  .modalContent {
    background-color: #fff;
    margin: 15% auto;
    padding: 1rem;
    border: 1px solid #888;
    width: 50%;
    border-radius: 8px;
  }

  .alertContainer {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .preview {
    width: 100%;
    height: 200px;
    border-radius: $border-radius;
    border: $input-border;
    overflow: hidden;

    &.information {
      background-color: #33ff3355;
    }
    &.warning {
      background-color: #ff993355;
    }
    &.deploy {
      background-color: #6666ff55;
    }

    .titleContainer {
      padding: 0 0.5rem;
      display: flex;
      gap: 1rem;
      width: 100%;
      height: 25%;
      background-color: #00000011;
      align-items: center;
      font-family: "Lato", sans-serif;
      text-transform: capitalize;
      border-bottom: $input-border;
    }

    .text {
      padding: 0 0.5rem;
      width: 100%;
      height: 100%;
    }
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;

    button {
      padding: 0.5rem 1rem;
      background-color: #007bff;
      border: none;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 1rem;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.noAlertModal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  &.show {
    display: block;
  }

  .modalContent {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    border-radius: 8px;
  }

  .alertContainer {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;

    .noAlertMessage {
      font-size: 1.2rem;
      color: #666;
    }
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;

    button {
      padding: 0.5rem 1rem;
      background-color: #007bff;
      border: none;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 1rem;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
