@import "src/styles/variables";

.container {
  width: 100%;


  

}

.label {
  width: 100%;
  color: $main-black;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.thumbContainer {
  height: 1rem;
  width: 1rem;
  border-radius: calc($border-radius / 2);
  background-color: $main-accent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trackContainer {
  height: 2rem;
  display: flex;
  width: 100%;
  cursor: pointer;
  padding-bottom: 1.5rem;
}

.line {
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 8px;
  height: 1px;
  background-color: $white-color;

  &:nth-child(2) {
    bottom: 6px;
  }

  &:nth-child(3) {
    bottom: 8px;
  }

  &:nth-child(4) {
    bottom: 10px;
  }
}

.tooltip {
  position: absolute;
  bottom: -28px;
  color: $main-black;
  font-size: .75rem;
  padding: .25rem;
  border-radius: calc($border-radius / 2);
}

.disabled {
  cursor: not-allowed;
}