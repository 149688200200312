@import "src/styles/variables";

.dialog {
  max-width: 368px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.row {
  display: flex;
  gap: 1.5rem;
  margin-top: .75rem;
  align-items: flex-start;
}

.bottomLabel {
  color: $main-gray;
  font-size: .75rem;
  margin-top: .5rem;
}
