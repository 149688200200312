@import "src/styles/variables";

.container {
  width: 100%;
}

.label {
  width: 100%;
  color: $main-black;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  font-family: "Lato", sans-serif;
}

.input {
  flex: 1;
  width: 100%;
  padding: 0.75rem 1rem;
  outline: none;
  border: none;
  border-radius: calc($border-radius / 2);
  box-sizing: border-box;
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
  color: $main-black;

  &.minPadding {
    padding: 0.25rem 0.5rem;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  border: $input-border;
  border-radius: calc($border-radius / 2);

  &.noBorder {
    border: none;
  }

  &.errorBorder {
    border: $border-red;

    & > input {
      color: $main-red;
    }
  }
}

.beforeIcon {
  color: $main-gray;
  opacity: 0.5;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  user-select: none;
}

.inputWithBeforeIcon {
  padding-left: 0.5rem;
}

.afterIcon {
  color: $main-gray;
  padding-right: 1rem;
  user-select: none;
}

.inputWithAfterIcon {
  padding-right: 0.5rem;
}
