@import "src/styles/variables";

.header {
  display: flex;
  border-bottom: $main-border;
  justify-content: space-between;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.left-column {
  width: 20rem;
  margin-right: 2.5rem;
}
.right-column {
  -webkit-flex: auto;
  flex: auto;
}

.label {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  display: flex;
  width: 20rem;
  margin-bottom: 0.5rem;
  align-items: center;
  color: $main-black;
}

.tzContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 0px 10px 0px;
  
  .labelTz {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    display: flex;
    width: 20rem;
    margin-bottom: 0.5rem;
    align-items: center;
    color: $main-black;
  }

  .spanTz {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #3333338a;
    background-color: #dbdbdb3d;
    padding: 2px 10px;
    border-radius: 20px;
    cursor: default;
  }
}

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background: $secondary-background;
  border: $card-border;
  border-radius: 8px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $main-black;
  &__inner {
    display: flex;
    align-items: center;
  }
  &__icon svg {
    margin-right: 6px;
    transform: translateY(-2px);
  }
}
.delete-file {
  border: none;
  background: transparent;
}

.limiter {
  width: 100%;
  border: $header-border;
  margin-block: 1rem;
}

.chartContainer {
  width: 100%;
  position: relative;

  .ETPValueSelector {
    width: 600px;
    display: flex;
    margin-inline: auto;
    gap: 0.5rem;
    flex-wrap: wrap;
    .option {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      display: flex;
      width: 20rem;
      margin-bottom: 0.5rem;
      align-items: center;
      color: $main-black;
      white-space: pre;
      background-color: #00000044;
      align-self: center;
      border-radius: 10px;
      padding: 1rem;
      flex:1;
      justify-content: center;

      cursor: pointer;

      &:hover {
        padding: calc(1rem - 5px);
        border-width: 5px;
        border-style: solid;
        border-color: #00000044;
      }


    }
    
    .active {
      padding: calc(1rem - 5px);
      border-width: 5px;
      border-style: solid;
    }
  }

  .downloadContainer {
    display: flex;
    width: fit-content;
    gap: 1rem;
    margin-left: auto;

    .chartToPngButton {
      padding: 2px;
      width: fit-content;
      height: fit-content;
      border-radius: 25%;
      background-color: transparent;
      color: black;
      cursor: pointer;
      transition: 10ms;

      &:active {
        transform: translateY(1px);
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  .labelsContainer {
    width: 90%;
    margin-inline: auto;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  .chartTitle {
    width: max-content;
    margin-inline: auto;
    text-decoration: underline;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    color: $main-black;
    display: flex;
    gap: .2rem;
  }
}
.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}

.indice {
  font-size: 70%;
  vertical-align: sub;
}

.YAxisName {
  position: absolute;
  top: 50%;
  left: -50px;
  transform: rotate(-90deg);
  color: #00000044;
  font-size: small;
}

.datasetData {
  width: 100%;
  display: flex;
  justify-content: space-between;
}