@import "src/styles/variables";

.header {
  display: flex;
  border-bottom: $main-border;
  justify-content: space-between;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.dataAnalysisTitle {
  margin-block: 2%;
  font-size: 1.25rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.list {
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
}

.chartList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: space-between;
}

.deleteButton {
  color: red;
}

.buttons {
  display: flex;
  button {
    margin: 0 8px;
  }
}
.error {
  border-bottom: $border-red !important;
}

.limiter {
  width: 100%;
  border: $header-border;
  margin-block: 1rem;
}
.verticalLimiter {
  border: $header-border;
}
.chartContainer {
  width: calc(50% - 0.5rem);

  .downloadContainer {
    display: flex;
    width: fit-content;
    gap: 1rem;
    margin-left: auto;

    .chartToPngButton {
      padding: 2px;
      width: fit-content;
      height: fit-content;
      border-radius: 25%;
      background-color: transparent;
      color: black;
      cursor: pointer;
      transition: 10ms;

      &:active {
        transform: translateY(1px);
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  .labelsContainer {
    width: 90%;
    margin-inline: auto;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  .chartTitle {
    width: max-content;
    margin-inline: auto;
    text-decoration: underline;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    color: $main-black;
  }
}

.ETPValueSelector {
  width: 30%;
}

