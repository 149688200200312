@import "src/styles/variables";

.error {
  color: $main-red;
  padding-left: 0.5rem;
  font-size: 0.825rem;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
}
