@import "src/styles/variables";

.header {
  display: flex;
  border-bottom: $main-border;
  justify-content: space-between;

}

.choices {
  display: flex;
  align-items: center;
  gap: 30px;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.datasets-type {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: $main-gray;
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 30px;
  align-items: center;
  padding: 35px;

  .choiceButton {
    display: flex;
    justify-content: center;
    gap: 50px;

    .meteonormContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        font-size: 0.875rem;
        color: #333837;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}

.loadingCardContainer {
  padding: 1rem 1.25rem;
  border-radius: $border-radius;
  border: $card-border;
  background: $main-background;
}

.loadingCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loadingFlex {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.loadingName {
  font-size: 0.875rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 150%;
}

.loadingIcon {
  transform: translateY(-2px);
}

.loaderContainer {
  max-width: 350px;;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waitingText {
  position: absolute;
  top: 6px;
  font-size: 0.875rem;
  color: $main-black;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 150%;
  font-size: 12px;
}

.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(233,233,233);
  overflow: hidden;
}
.loader::after {
  content: '';
  width: 192px;
  height: 4.8px;
  background: rgb(86, 161, 121);;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
    