@import "src/styles/variables";

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 40px;
  align-items: center;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  border: $header-border;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: $main-black;
  margin-left: 0.5rem;
}

.you {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: $main-gray;
  margin-left: 0.25rem;
}

.email {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: $main-black;
}

.delete {
  border: none;
  background: none;
  padding: 0;
  color: $main-red;

  &:disabled {
    opacity: 0.5;
  }
}
