@import "src/styles/variables";

.flex {
  display: flex;
  gap: 1rem;
}

.list {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.section-title {
  font-weight: 700;
  font-size: 16px;
  color: $main-black;
  margin-bottom: 16px;
}
.table {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;
  margin-bottom: 10px;
  width: 90%;

  th {
    border-left: $header-border;
    border-top: $header-border;
    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.85rem;
    color: $main-black;
    width: 15%;
    border-top: none;
    font-weight: 700;
  }

  td {
    border-left: $header-border;
    border-top: $header-border;
  }

  td:first-child,
  th:first-child {
    border-left: none;
    width: 45%;
  }

  td:last-child,
  th:last-child {
    width: 15%;
  }
}

.td-element {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-family: "Lato", sans-serif;
  font-size: 0.85rem;
  height: 3.7rem;


  &.error {
    border-radius: 10px;
    border: 1px red solid;
  }
}

.selector {
  width: 50%
}

.degreeInfoElement {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .5rem;

  .selector-title {
    font-weight: 500;
    font-size: 12px;
    color: $main-black;
    display: flex;
    gap: .2rem;
    width: max-content;
  }
}

.order-selector {
  margin-top: auto;
  margin-bottom: 12px;

  .item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    height: calc(3.7rem + 2px);
  }
}
.button {
  background: none;
  border: none;
}

.deleteButton {
  color: red;
}

.arrow-down {
  margin-left: 8px;
  svg {
    transform: translateY(-1px);
  }
}
.error {
  border-bottom: $border-red;
}
.buttonContainer {
  display: flex;
  gap: 1rem;
  .selectTemplate {
    svg {
      width: 20px;
      height: auto;

    }
  }
}
.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}
