@import "src/styles/variables";

.logo {
  height: 20px;
  width: 112px;
  margin-right: 8px;
}

.header {
  padding: .5rem 2.5rem;
  display: flex;
  align-items: center;
  border-bottom: $header-border;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 375px;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}

.title {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: $main-black;
  margin-top: 2.5rem;
}

.text {
  font-family: 'Lato', sans-serif;
  font-size: 0.875rem;
  line-height: 150%;
  color: $main-gray;
  text-align: center;
  margin-top: 1rem;
}

.link {
  margin-top: 2rem;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: $white-color;
  padding: 0.75rem 1.25rem;
  background: $main-accent;
  border-radius: $border-radius;
  border: $border-accent;

  &:hover {
    color: $main-accent;
    background: $white-color;
  }
}
