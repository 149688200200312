@import "src/styles/variables";

.zone {
  width: 100%;
  max-width: 660px;
  border: 1px dashed $main-gray;
  padding: 50px;
  height: 238px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &__image {
    margin-bottom: 16px;
  }
  &__title {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: $main-black;
  }
  &--error {
    border: 1px dashed rgb(182, 43, 43);
  }
}
.input-fide {
  display: none;
}
.here {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: $main-gray;
  span{
    color: #56A179;
  }
}
