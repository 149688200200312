.pointContainer {
	font-size: small;

	background-color: #aaa;
	border-radius: 15%;

	padding-inline: 2px;
}

.button {
	background: none;
	border: none;
}

.deleteButton {
color: red;
}

.compass {
	position: absolute;
	z-index: 999;
	transition: all 0.01s;

	right: 5px;
	top: 5px;

	padding: 5px;
	border-radius: 100%;

	overflow: hidden;

	background-color: #aaa;
	img {
		height: 30px;
		width: 30px;
	}
}
