@import "src/styles/variables";

.headerRow {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.deleteButton {
  color: $main-red;
}
