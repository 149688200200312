@import "src/styles/variables";

.main {
  padding: .875rem 1.25rem;
}

.header {
  display: flex;
  gap: 1rem;
  border-bottom: $header-border;
  justify-content: space-between;
  align-items: center;
  padding: .875rem 1.25rem;
}

.body {
  border-radius: $border-radius;
  background-color: $white-color;
  height: fit-content;
}
