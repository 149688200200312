@import "src/styles/variables";

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.rightSection {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: $main-stroke;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}

.flex {
  display: flex;
  gap: 1rem;
}

.sectionTitle {
  font-size: 1rem;
  color: $main-black;
  font-weight: 700;
  display: flex;
  gap: .5rem;
}

.mt {
  margin-top: 1.5rem;
}

.blur {
  filter: blur(5px);
  cursor: not-allowed;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: baseline;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawButton {
  color: $main-accent;
  padding: 0.25rem;

  &:hover {
    background-color: transparent;
  }
}

.button {
  background: none;
  border: none;
}

.addPoint {
  width: fit-content;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: $main-border;
  border-radius: $border-radius;

  td,
  th {
    border-left: $main-border;
    border-top: $main-border;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;
  }

  th {
    border-top: none;
    font-weight: 700;
  }

  td:first-child,
  th:first-child {
    border-left: none;

    width: 70%;
  }

  td:last-child,
  th:last-child {
    width: 10%;
  }
}

.checkbox-label {
  display: flex;
  gap: .25rem;
}

.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}

.renderContainer {
  position: relative;
  // height: 300px;
  border-radius: $border-radius;
  border: 1px #ccc solid;
  padding: .5rem;
}

.visuContainer {
  aspect-ratio: 1 / 1;
  width: 70%;
  margin-inline: auto;
  margin-top: .5rem;
}
