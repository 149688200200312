@import "src/styles/variables";

.headerRow {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
}

.progressText {
  color: $main-black;
  font-family: "Lato", sans-serif;
}

.button {
  background: none;
  border: none;
  padding: 0;
  margin: auto;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.flex {
  display: flex;
}
.spaceBetween {
  justify-content: space-between;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  border-radius: $border-radius;
  background-color: $secondary-background;
}

.twoInput {
  width: 4rem;
}

.successRow {
  background-color: #f8fffb;
}

.failedRow {
  background-color: #fff6f3;
}

.link {
  color: inherit;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableContainerFirst {
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;
  width: calc(100vw - 300px - 4rem - 3px);
}

.tableContainer {
  overflow-x: auto;
  padding-bottom: 1rem;
  width: calc(100vw - 300px - 6rem - 3px);
}

.table {
  width: 100%;
  border: 1px solid #e9f2e9;
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;

  tbody,
  tr {
    border-radius: $border-radius;
  }

  td,
  th {
    border-left: $header-border;
    border-top: $header-border;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;
    text-align: center;
    & input {
      background: transparent;
      text-align: center;
    }
  }

  td:not(:last-child),
  th:not(:last-child) {
    min-width: 11rem;
  }

  th {
    border-top: none;
    font-weight: 700;
    white-space: nowrap;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

.dataTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;

  tbody,
  tr {
    border-radius: $border-radius;
  }

  td,
  th {
    border-left: $header-border;
    border-top: $header-border;
    max-width: 11rem;

    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.875rem;
    color: $main-black;
    text-align: center;
    // width: 200px;
    min-width: 125px;

    & input {
      background: transparent;
      text-align: center;
    }
  }

  th {
    border-top: none;
    font-weight: 700;
    position: relative;
    // white-space: nowrap;

    .subTitle {
      font-size: 0.65rem;
    }
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

.deleteButton {
  color: $main-red;
}

.halfChartContainer {
  // background-color: red;
  height: 50vh;
  width: 49%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e9f2e9;

  .chart {
    padding-top: 15px;
    background-color: #fbfcfb;

    position: relative;

    width: 100%;
    height: 90%;

    .unit {
      position: absolute;
      top: -4px;

      left: 20px;
      &.right {
        left: unset;
        right: 20px;
      }
    }
  }
  .title {
    // width: max-content;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-family: "Lato", sans-serif;
    font-size: 1.3rem;
    color: $main-black;
    margin-bottom: 15px;
    text-align: center;
  }
}

.downloadContainer {
  display: flex;
  gap: 1rem;
  .chartToPngButton {
    padding: 2px;
    width: fit-content;
    height: fit-content;
    border-radius: 25%;
    background-color: transparent;
    color: black;
    cursor: pointer;
    transition: 10ms;

    &:active {
      transform: translateY(1px);
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  position: absolute;
  top: 5px;
  right: 10px;
  transform: translateY(-6px);
}
