@import "src/styles/variables";


.degreeInfoElement {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .5rem;
  margin-bottom: .5rem;

  .selector-title {
    font-weight: 500;
    font-size: 12px;
    color: $main-black;
    display: flex;
    gap: .2rem;
    width: max-content;
  }
}

.table-container {
  width: 70vw;
  overflow: scroll;
}

.flex {
  display: flex;
  gap: 1rem;
}
.section-title {
  font-weight: 700;
  font-size: 16px;
  color: $main-black;
  margin-bottom: 16px;
}
.table {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  table-layout: auto;


  th {
    border: $header-border;
    padding: 0.75rem 1rem;
    font-family: "Lato", sans-serif;
    font-size: 0.85rem;
    color: $main-black;
    border-top: none;
    font-weight: 700;
  }

  td {
    border: $header-border;
    text-align: center;
    // overflow: scroll;
  }

  td:last-child {
    border-right: $header-border;
    width: 5%;
  }
  
}

.td-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-size: 0.85rem;
}

.td-element {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-family: "Lato", sans-serif;
  font-size: 0.85rem;
  height: 3.7rem;
  justify-content: center;
}

.button {
  background: none;
  border: none;
}

.deleteButton {
  color: red;
}

.arrow-down {
  margin-left: 8px;
  svg {
    transform: translateY(-1px);
  }
}
.error {
  border: $border-red;
}

.list {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  display: flex;
  justify-content: center;
}
.title-element {
  width: max-content;
  border-left: none;
  border-top: none;
  background-color: $main-gray;
  display: flex;
  margin-inline: auto;
  padding: 0.75rem 0.75rem;
}

.icon-container {
  display: flex;
  align-self: center;
}