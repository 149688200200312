@import "src/styles/variables";

.container {
  padding: 1rem;
  background: $secondary-background;
  border-radius: $border-radius;
  border: 3px solid $secondary-background;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.checkBox {
    cursor: pointer;

  }

  &.error {
    border-color: $main-red;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.canDropBorder {
    border-style: dashed;
    border-color: $main-accent;
  }

}

.soilContainer {
  padding: 1rem;
  background: $secondary-background;
  border-radius: $border-radius;
  border: 3px solid $secondary-background;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.error {
    border-color: $main-red;
  }

  &.canDropBorder {
    border-style: dashed;
    border-color: $main-accent;
  }

}

.toggle {
  border: none;
  background: none;
  transition: .15s linear;
}

.toggleOpen {
  transform: rotate(90deg);
}

.label {
  user-select: none;
  color: $main-black;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .labelText {
  }

  svg rect {
    fill: $main-stroke;
  }
  svg path {
    stroke: $main-stroke;
  }
}

.checkbox-label {
  user-select: none;
  color: $main-black;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  svg {

    width: 25px;
    height: 25px;
    color: $main-gray;
  }
}

.dnd {
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  color: $main-gray;
  border-radius: $border-radius;
  border: 1px dashed $main-gray;
}

.row {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  gap: 1rem;
  align-items: center;

}

.ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.li {
  display: flex;
  align-items: center;
  padding: 0.125rem 0;
}

.doted {
  flex: 1;
  height: 0;
  margin: 0 1rem;
  border-top: 1px dashed $main-gray;
}

.text {
  color: $main-gray;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
}

.selector {
  width: max-content;
  border: 1px black solid;
  border-radius: $border-radius;
}