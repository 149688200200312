$white-color: #fff;
$main-black: #333837;
$main-gray: #727675;
$main-accent: #56a179;
$main-stroke: #e0e0e0;
$main-background: #f9f9f9;
$main-red: rgb(240, 37, 37);
$main-yellow: #c8ba39;
$secondary-accent: #f0fff7;
$secondary-yellow: #fffdeb;
$secondary-red: #ffcdd2;
$secondary-background: #fbfcfb;

$main-border: 1px solid #f8f8f8;
$header-border: 1px solid $main-stroke;
$card-border: 1px solid #e9f2e9;
$input-border: 1px solid rgba(114, 118, 117, 0.4);
$border-red: 1px solid $main-red;
$border-accent: 1px solid $main-accent;
$border-radius: 8px;
