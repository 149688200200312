@import "src/styles/variables";

.wrapper {
  margin: 2.5rem auto 0;
  max-width: 1240px;
  padding: 0 1.25rem;
}

.headerSection {
  margin-bottom: 2rem;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.control {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.title {
  font-size: 2rem;
  color: $main-black;
  display: inline-block;
}

.subtitle {
  color: $main-gray;
  font-size: 0.75rem;
  max-width: 60%;
}

.sortSection {
  margin-bottom: 1.5rem;
}

.projectsSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1.25rem;
  background-color: $white-color;
  border-radius: $border-radius;
}

.gray {
  color: $main-gray;
}

.dropHighlight {
  border: 1px dashed #4caf50;
  background-color: rgba(76, 175, 80, 0.1);
}
