@import "src/styles/variables";

.container {
  padding: 1.5rem;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: .75rem;
  right: .75rem;
  background: none;
  border: none;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.title {
  font-family: 'Lato', sans-serif;
  color: $main-black;
  font-size: 1.5rem;
  font-weight: 700;
}

.subTitle {
  font-family: 'Lato', sans-serif;
  color: $main-gray;
  font-size: 0.875rem;
}
