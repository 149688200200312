@import "src/styles/variables";

.grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: calc(100vw - 24rem);
}

.headerRow {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.title {
  font-size: 1.125rem;
  color: $main-black;
}

.progressText {
  color: $main-black;
  font-family: "Lato", sans-serif;
}

.button {
  background: none;
  border: none;
  padding: 0;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.twoInput {
  width: 4rem;
}

.successRow {
  background-color: #f8fffb;
}

.failedRow {
  background-color: #fff6f3;
}

.link {
  color: inherit;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableContainer {
  overflow-x: auto;
  padding-bottom: 1rem;
}

// .table {
//   border-collapse: separate;
//   border-spacing: 0;
//   border: $header-border;
//   border-radius: $border-radius;

//   tbody,
//   tr {
//     border-radius: $border-radius;
//   }

//   td,
//   th {
//     border-left: $header-border;
//     border-top: $header-border;

//     padding: 0.75rem 1rem;
//     font-family: "Lato", sans-serif;
//     font-size: 0.875rem;
//     color: $main-black;

//     & input {
//       background: transparent;
//       text-align: center;
//     }
//   }

//   td:not(:last-child),
//   th:not(:last-child) {
//     min-width: 11rem;
//   }

//   th {
//     border-top: none;
//     font-weight: 700;
//     white-space: nowrap;
//   }

//   td:first-child,
//   th:first-child {
//     border-left: none;
//   }

//   tr:last-child {
//     td:first-child {
//       border-bottom-left-radius: $border-radius;
//     }

//     td:last-child {
//       border-bottom-right-radius: $border-radius;
//     }
//   }
// }

.deleteButton {
  color: $main-red;
}

.disable {
  cursor: not-allowed;
}

.waterBalanceChartsContainer {
  display: flex;
  width: 100%;

  .waterBalanceTable {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;

    th,
    td {
      border: 1px gray solid;
      padding: 0.2rem 0.5rem;
    }
    td {
      text-align: center;
    }
    th {
      background: #ddd;
    }
  }

  .chartToPngButton {
    padding: 2px;
    width: fit-content;
    height: fit-content;
    border-radius: 25%;
    background-color: transparent;
    color: black;
    cursor: pointer;
    transition: 10ms;

    &:active {
      transform: translateY(1px);
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.dataTable {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;
  width: 100%;

  tbody,
  tr {
    td:first-child {
      text-align: left;
      width: min-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }

    border-radius: $border-radius;
  }

  .btnDownloadSimu {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  td,
  th {
    border-left: $header-border;
    border-top: $header-border;
    text-align: center;
    padding: 0.1rem 0.25rem;
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    color: $main-black;
    white-space: nowrap;

    & input {
      background: transparent;
      text-align: center;
    }
  }

  th {
    position: relative;
    min-width: 140px;
    border-top: none;
    font-weight: 700;
    padding: 7px;

    .subTitle {
      font-size: 0.65rem;
    }
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }

  tr.mean {

    td,
    th {
      border-top: $main-stroke double;
    }
  }
}
.help-icon {
  svg {
    height: 12px;
    width: 12px;
  }
  position: absolute;
  top: 2px;
  right: 0px;
  transform: translateY(-6px);
}

.flex {
  display: flex;
}
.spaceBetween {
  justify-content: space-between;
}

.halfChartContainer {
  // background-color: red;
  height: 50vh;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e9f2e9;
  margin-top: 10px;
  
  .chart {
    padding-top: 15px;
    background-color: #fbfcfb;

    position: relative;

    width: 100%;
    height: 90%;

    .unit {
      position: absolute;
      top: -4px;

      left: 20px;
      &.right {
        left: unset;
        right: 20px;
      }
    }
  }
  .title {
    // width: max-content;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-family: "Lato", sans-serif;
    font-size: 1.3rem;
    color: $main-black;
    margin-bottom: 15px;
    text-align: center;
  }
}

.downloadContainer {
  display: flex;
  gap: 1rem;
  .chartToPngButton {
    padding: 2px;
    width: fit-content;
    height: fit-content;
    border-radius: 25%;
    background-color: transparent;
    color: black;
    cursor: pointer;
    transition: 10ms;

    &:active {
      transform: translateY(1px);
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.optionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .uniteSelector {
    display: flex;
    gap: 1rem;
    border: $input-border;
    padding: 0.25rem;
    border-radius: 10px;
    background-color: #00000011;
    width: 250px;
  }
}

.justifyCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

