@import "src/styles/variables";

.dialog {
  max-width: 368px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.row {
  display: flex;
  gap: 1.5rem;
  margin-top: .75rem;
}

.delete {
  background: $main-red;
  border: $border-red;

  &:hover:not([disabled]) {
    color: $main-red;
  }
}

.email {
  font-family: 'Lato', sans-serif;
  font-size: 0.875rem;
  color: $main-black;
}
